import React from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from 'stateHandling/hooks'

const PlayAnswerChildren = () => {
	const { t } = useTranslation()
	const audioUrl = useAppSelector(state => state.currentExercise.recordedAudio)
	const recordedAudio = new Audio(audioUrl)

	return (
		<div className='text-center' data-cy='play_answer_button_children'>
			<Button className='exercise--record-button text-nowrap rounded-circle' variant='primary' onClick={() => recordedAudio.play()}>
				<FontAwesomeIcon icon={faVolumeUp} />
			</Button>
			<h5 data-cy='play_answer_text_children'>{t('exercise.play_answer.title')}</h5>
		</div>
	)
}

export default PlayAnswerChildren