import { useTranslation } from 'react-i18next'
import { Badge, Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import ImageTags from 'components/ImageTags'
import { setCurrentEditImage } from 'reducers/imageSlice'
import { AWS_URL } from 'utils/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { userIsAdmin } from 'utils/helpers'

const ImageCard = ({ image, langCode }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)

	// Event handler for modify icon
	const editImage = image => () => {
		dispatch(setCurrentEditImage(image))
	}

	return (
		<span data-cy='image_library_image_card' className='images--card-container' key={image.id}>
			<div className='d-flex justify-content-center'>
				<LazyLoadImage
					data-cy="image_library_add_exercise_image"
					alt='Image'
					className= 'images--image'
					key={AWS_URL + image.url}
					effect="blur"
					height={226}
					placeholderSrc = {AWS_URL + image.url}
					src={AWS_URL + image.url}
				/>
			</div>
			{userIsAdmin(user.data) ?
				<>
					<FontAwesomeIcon
						icon={faEdit}
						onClick={editImage(image)}
						className='image--edit-icon'
						data-cy='edit-image-icon'/>
					<Container>
						<div data-cy='image_id'>{t('images.id')} {image.id}</div>
						<div data-cy='image_name'>{t('images.name')} {image.name}</div>
						<div data-cy="image_library_image_category_text">
							{t('images.category')}
							<Badge data-cy="image_library_image_category_badge" className='p-2 m-1' bg="secondary">
								{t(`add_image.${image.category}`)}
							</Badge>
						</div>
						<ImageTags
							image={image}
							langCode={langCode}
						/>
					</Container>
				</>
				: <Container>
					<div data-cy="image_library_image_category_text">
						{t('images.category')}
						<Badge data-cy="image_library_image_category_badge" className='p-2 m-1' bg="secondary">
							{t(`add_image.${image.category}`)}
						</Badge>
					</div>
					<ImageTags
						image={image}
						langCode={langCode}
					/>
				</Container>}
			<OverlayTrigger
				key={`preview-tooltip-${image.id}`}
				trigger={['hover']}
				placement='bottom'
				style={{ 'float': 'right'}}
				overlay={
					<Tooltip id={`preview-tooltip-${image.id}`}>
						{t('images.download')}
					</Tooltip>
				}>
				<a href={AWS_URL + image.url} download>
					<FontAwesomeIcon className='image--edit-icon m-3' icon={faCloudArrowDown} />
				</a>
			</OverlayTrigger>
		</span>
	)
}

export default ImageCard