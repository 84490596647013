import { Card, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { setExerciseDuration } from 'reducers/currentExerciseSlice'
import { updateUserPoints } from 'reducers/userSlice'
import Confetti from 'react-confetti'
import React, { useEffect, useState } from 'react'
import Hotkeys from 'react-hot-keys'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Image from 'react-bootstrap/Image'

import timer from 'timer/index'
import { Dispatch } from 'types/Types'

import finishedExerciseCalm from 'sounds/finish_exercise_calm.mp3'
import finishedExerciseCelebrate from 'sounds/finish_exercise_celebrate.mp3'

import Coin from 'images/coin.png'
import Success from 'images/avatars/dialo_correct.png'
import Ribbon from 'images/ribbon.png'


interface Props {
	handleFinished:() => void
}
const FinishedChildren = ({ handleFinished }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch<Dispatch>()

	const user = useAppSelector(state => state.user.data)
	const length = useAppSelector(state => state.currentExercise.length)
	const stats = useAppSelector(state => state.currentExercise.stats.numCorrect)
	const startTime = useAppSelector(state => state.currentExercise.stats.timer.start)
	const coins = useAppSelector(state => state.currentExercise.stats.coins)

	const [numberOfConfettiPieces, setnumberOfConfettiPieces] = useState(300)

	useEffect(() => {
		playFinishedSound()
		const exerciseDuration = timer.getDuration(startTime)
		dispatch(setExerciseDuration(exerciseDuration))
		setTimeout(() => {
			setnumberOfConfettiPieces(0)
		}, 3500)
	}, [])

	useEffect(() => {
		if (user.type === 'user') {
			const data = new FormData()
			data.append('id', user.id)
			data.append('points', coins.toString())
			dispatch(updateUserPoints(data))
		}
	}, [])

	const playFinishedSound = () => {
		if (stats >= 2 * length / 3) {
			const finishedExerciseCelebrateSound = new Audio (finishedExerciseCelebrate)
			finishedExerciseCelebrateSound.play()
		} else {
			const finishedExerciseCalmSound = new Audio (finishedExerciseCalm)
			if (finishedExerciseCalmSound) finishedExerciseCalmSound.play()
		}
	}

	const finishedContent = () => {
		const showConfetti = stats >= 2 * length / 3
		return showConfetti
			? <Confetti
				numberOfPieces={numberOfConfettiPieces}
				width={window.innerWidth} height={window.innerHeight}/>
			: <></>
	}

	return (
		<>
			{finishedContent()}
			<Row className='align-items-center justify-content-center flex-grow-1' style={{ 'background': 'rgba(158, 119, 237, 0.1)' }}>
				<Col className='d-flex flex-column align-items-center mt-4' xs={3}>
					<div className='exercise-finished-ribbon-container'>
						<Image src={Ribbon} style={{ 'width': '25rem' }} />
						<div className='exercise-finished-ribbon-text' data-cy='exercise-finished-ribbon'>{t('exercise.completed.top_bar_text_2')}</div>
					</div>
					<Image src={Success} style={{ 'height': '20rem' }} />
					<Card className='exercise-finished-card'>
						<Card.Body data-cy='exercise-finished-coins-card'>
							<h3 className='exercise-finished--text-medium text-center'>
								{coins} <Image src={Coin} height='50' />
							</h3>
						</Card.Body>
					</Card>
				</Col>

			</Row>
			<Row className="exercise--footer-fixed justify-content-center align-items-center">
				{/* 
				Circle buttons for retry/finish
				Kept for later if we want to have retry button on this page as well
				<Col className='text-end' xs={6}>
					<Hotkeys keyName="" onKeyDown={}>
						<Button
							variant='outline-primary'
							className='text-nowrap exercise--record-button rounded-circle mx-5'
							data-cy=''
							onClick={}
						>
							<FontAwesomeIcon className='' icon={faRotateRight} />					
						</Button>
					</Hotkeys>
				</Col> 
				<Col className='' xs={6}>
					<Hotkeys keyName="enter" onKeyDown={handleFinished}>
						<Button
							autoFocus={true} variant='primary'
							className='text-nowrap exercise--record-button rounded-circle mx-5'
							data-cy='exercise_finished_return_button'
							onClick={handleFinished}>
							<FontAwesomeIcon className='' icon={faCheck} />					
						</Button>
					</Hotkeys>
				</Col>
				*/}
				<Col className='text-center'>
					<Hotkeys keyName="enter" onKeyDown={handleFinished}>
						<Button
							autoFocus={true} variant='primary'
							className='text-nowrap exercise--next-button px-5 py-4 fs-5'
							data-cy='exercise_finished_return_button'
							onClick={handleFinished}>
							<FontAwesomeIcon className='exercise--next-button-icon' icon={faCheck} />
							{t('exercise.completed.back_btn')}
						</Button>
					</Hotkeys>
				</Col>
			</Row>
		</>
	)
}

export default FinishedChildren