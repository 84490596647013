import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Hotkeys from 'react-hot-keys'

interface Props {
    handleQuestion:(correct: boolean) => void
	handleRetry:() => void
    retry:boolean
}

const StatusPhonology = ({ handleQuestion, handleRetry, retry=false }: Props) => {
	const { t } = useTranslation()

	const onKeyDown = (keyName, event) => {
		event.preventDefault()
		handleQuestion(true)
	}

	return (
		<Row className='exercise--footer-fixed flex-grow-1 justify-content-center'>
			<Col className='text-center flex-grow-1 align-self-center'>
				<div className='mb-3'style={{ fontSize: '1.5rem', lineHeight: '100%' }}>{t('exercise.listen_view.question')}</div>
				{retry
					? <div>
						<Button
							className='text-nowrap exercise--record-button rounded-circle mx-5'
							onClick={handleRetry}
							variant={'outline-primary'}
							data-cy='exercise_answer_retry_button'>
							<FontAwesomeIcon icon={faRotateRight} />
						</Button>
						<Hotkeys keyName="enter" onKeyDown={onKeyDown}>
							<Button
								className='text-nowrap exercise--record-button rounded-circle mx-5'
								onClick={() => handleQuestion(true)}
								variant={'primary'}
								data-cy='exercise_answer_correct_button'>
								<FontAwesomeIcon icon={faCheck} />
							</Button>
						</Hotkeys>
					</div>
					: <div>
						<Button
							className='text-nowrap exercise--record-button rounded-circle mx-5'
							onClick={() => handleQuestion(false)}
							variant={'outline-primary'}
							data-cy='exercise_answer_incorrect_button'>
							<FontAwesomeIcon icon={faXmark} />
						</Button>
						<Hotkeys keyName="enter" onKeyDown={onKeyDown}>
							<Button
								className='text-nowrap exercise--record-button rounded-circle mx-5'
								onClick={() => handleQuestion(true)}
								variant={'primary'}
								data-cy='exercise_answer_correct_button'>
								<FontAwesomeIcon icon={faCheck} />
							</Button>
						</Hotkeys>
					</div>
				}
			</Col>
		</Row>
	)
}

export default StatusPhonology