import React from 'react'
import { motion } from 'framer-motion'
import FadeInImage from 'components/ExerciseFeedback/FadeInImage'
import CorrectImage from 'images/avatars/dialo_correct.png'
import Star from 'images/exercise_feedback/star.png'
import './ExerciseFeedback.css'

const FeedbackCorrectChildren = () => {

	const generateStars = (count: number) => {
		return Array.from({ length: count }, (_, i) => (
			<motion.img
				key={i}
				src={Star}
				className='exercise--star-animation'
				alt={'Star'}
				initial={{ opacity: 0, x: 0, y: 0, scale: 0.5 }}
				animate={{
					opacity: [0, 1, 0],
					x: Math.random() * window.innerWidth - window.innerWidth / 2,
					y: Math.random() * window.innerHeight - window.innerHeight / 2,
					scale: Math.random() + 0.5,
					rotate: Math.random() * 360,
				}}
				transition={{
					duration: 1.5,
					delay: Math.random() * 0.5,
					ease: 'easeOut'
				}}
			/>
		))
	}

	return (
		<div className='exercise--animation-container' data-cy='feedback_correct_image'>
			{generateStars(30)}
			<FadeInImage
				image={CorrectImage}
				success={true}
			/>
		</div>
	)
}

export default FeedbackCorrectChildren