import React, {useEffect , useState} from 'react'
import { useDispatch } from 'react-redux'
import { Tabs, Tab, Row, Col, Image, Container} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'

import { getPatientStatistics } from 'reducers/currentPatientSlice'

import ExerciseListPage from 'components/PracticeUserExerciseList/PracticeUserExerciseList'
import PracticeProgress from 'components/PracticeProgress'
import LogOutButton from 'components/LogOutButton'
import LanguageSwitcher from 'components/LanguageSwitcher'

import { useAppSelector } from 'stateHandling/hooks'
import { userWithAssignedExercises } from 'utils/helpers'

import { Dispatch } from 'types/Types'
import { PatientUser } from 'types/UserTypes'

import dialogLogo from 'dialog_logo_inverted_text_purple.png'
import Coin from 'images/coin.png'

import './PracticePage.css'

enum TabKey {
    PRACTICE = '0',
    PROGRESS = '1'
}

const tabComponents = {
	[TabKey.PRACTICE]: () => <ExerciseListPage />,
	[TabKey.PROGRESS]: (isAdult:boolean) => <PracticeProgress isAdult={isAdult} />,
}

const PracticePage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const [tabIndex, setTabIndex] = useState<TabKey>(TabKey.PRACTICE)

	const filteredExercises = useAppSelector(state => state.exerciseList.filteredExercises)
	const user = useAppSelector(state => state.user.data) as PatientUser

	/**
	* Get the statistics, when the filtered exercises are fetched
	*/
	useEffect(() => {
		if (filteredExercises.length > 0) {
			dispatch(getPatientStatistics(user.cognitoId))
		}
	}, [filteredExercises])

	return (
		<>
			<Container>
				<Row className='exerciseMenu--header'>
					<Col style={{flex: 100}}>
						<Image
							src={dialogLogo}
							width="130"
							className="d-inline"
							alt="Dialog logo"
						/>
					</Col>
					<Col>
						<div className='text-nowrap'>
							<h5 data-cy='username_display' className='exerciseMenu--patient-username my-0'>
								<FontAwesomeIcon icon={faUser} className='mx-2'/>
								{user.username}
							</h5>
						</div>
					</Col>
					{ !userWithAssignedExercises(user)
						? <Col className='exerciseMenu--languageSelector-wrapper'>
							<LanguageSwitcher />
						</Col>
						: <></>
					}
					<Col>
						<LogOutButton/>
					</Col>
				</Row>
				{ !user.isAdult
					? <div className='mb-5 mt-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} data-cy='practice_user_coin_div'>
						<Image src={Coin} height='50' style={{ marginRight: '10px' }} /> {user.gamification ? user.gamification.points : 0}
					</div>
					: <></>
				}
				<Tabs
					defaultActiveKey={tabIndex}
					className='practice-page-tabs mb-2 ms-3 me-3'
					onSelect={(idx) => setTabIndex((idx as TabKey) || TabKey.PRACTICE)}
					data-cy='practice_user_tabs'
				>
					<Tab eventKey={TabKey.PRACTICE}
						title={
							<>
								<CalendarViewMonthIcon style={{ marginRight: '10px' }} />
								{(t('practice_tabs.practice'))}
							</>
						} />
					<Tab eventKey={TabKey.PROGRESS}
						title={
							<>
								<FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }}/>
								{(t('practice_tabs.progress'))}
							</>
						} />
				</Tabs>
				{tabComponents[tabIndex](user.isAdult)}
			</Container>
		</>
	)
}

export default PracticePage
