import { useTranslation } from 'react-i18next'
import HubspotForm from 'react-hubspot-form'

const HubspotFeedbackForm = () => {
	const { t } = useTranslation()
	return (
		<div data-cy='dashboard-feedback-form'>
			<h3>{t('navigation.dashboard.feedback.feedback_title')}</h3>
			<p>{t('navigation.dashboard.feedback.feedback_description')}</p>
			<HubspotForm
				portalId='26992203'
				formId='76210291-127a-4b55-9fdf-b5d3ef73ab12'
				onSubmit={() => console.log('Submit!')}
				onReady={(form) => console.log('Form ready!')}
				loading={<div>Loading...</div>}
			/>
		</div>
	)
}

export default HubspotFeedbackForm