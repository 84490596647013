import React, { useState } from 'react'
import FadeInImage from 'components/ExerciseFeedback/FadeInImage'
import ListenImage from 'images/avatars/dialo_feedback_listen.png'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button } from 'react-bootstrap'
import PlayAnswerChildren from 'components/PlayAnswerChildren'
import './ExerciseFeedback.css'
import AnswerModal from 'components/Answers/AnswerModal'

const FeedbackArticulationChildren = () => {
	const { t } = useTranslation()
	const [show, setShow] = useState(false)

	return (<>
		<AnswerModal
			show={show}
			handleClose={() => setShow(false)}
		/>
		<Row className='exercise--div-question mb-2 flex-grow-1 align-items-center justify-content-center'>
			<Col xs={4} sm={4}>
				<PlayAnswerChildren />
				<div className='text-center'>
					<Button className='text-nowrap p-2 mt-3' variant='outline-primary' onClick={() => setShow(true)}>{t('exercise.listen_view.show_answers')}</Button>
				</div>
			</Col>
			<Col xs={8} sm={8}>
				<div className='exercise--animation-container'>
					<FadeInImage
						image={ListenImage}
						success={true}
					/>
				</div>
				<div style={{ fontSize: '1.5rem', lineHeight: '100%', textAlign: 'center' }} data-cy='listen_answer_text_children'>
					{t('exercise.listen_view.listen_answer')}
				</div>
			</Col>
		</Row>
	</>
	)
}

export default FeedbackArticulationChildren