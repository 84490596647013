import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


import userService from 'services/users'
import {
	setUseAssignedExercises,
	setAutoPlayAudio,
	setTextSize,
	setResetDate,
	setMessage,
	getPatientData
} from 'reducers/currentPatientSlice'
import { getPatientList, setPatientListLoading } from 'reducers/userSlice'
import { changeCreateLoading } from 'reducers/appStateSlice'
import { Dispatch } from 'types/Types'
import ConfirmUserMessageModal from 'components/PatientPage/ConfirmUserMessageModal'

interface Props {
    id: string
    changeUserMessage: (variant: string, text: string) => void
}

const PracticePeriodSettings = ({ id, changeUserMessage }: Props ) => {
	const { t } = useTranslation()
	const [editMode, setEditMode] = useState(false)
	const [showUserMessageConfirmModal, setShowUserMessageConfirmModal] = useState(false)
	const user = useAppSelector(state => state.user)
	const patient = useAppSelector(state => state.currentPatient)
	const dispatch = useDispatch<Dispatch>()

	const saveUserSettingsEvent = event => {
		event.preventDefault()
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
		setShowUserMessageConfirmModal(true)
	}

	const saveUserSettings = async () => {
		try {
			dispatch(changeCreateLoading(true))

			const data = new FormData()
			data.append('id', id ? id : patient.id)
			data.append('assignedExercises', patient.settings.assignedExercises.toString())
			data.append('message', patient.message)
			data.append('resetDate', patient.settings.resetDate)
			/* TODO: add this back when implemented
            data.append('autoPlayAudio', patient.settings.autoPlayAudio)
            data.append('textSize', patient.settings.textSize) */
			await userService.updateUserSettings(data)
			changeUserMessage('success', `${t('edit_patient.messages.success_settings')}: ${patient.username}`)
			// update all patients with new data
			dispatch(setPatientListLoading(true))
			dispatch(getPatientList(user.data.id))
			setEditMode(false)
		} catch (err) {
			console.error('Error: ', err)
			changeUserMessage('danger',`${t('edit_patient.messages.failure_settings')}: ${err.response.data}`)
			setEditMode(false)
		}
	}

	const handleAssignedExercisesChange = (event) => {
		dispatch(setUseAssignedExercises())
	}

	const handleAutoPlayAudioChange = (event) => {
		dispatch(setAutoPlayAudio())
	}

	const handleTextSizeChange = (event) => {
		const size = event.target.value
		dispatch(setTextSize(size))
	}

	const handleResetDateChange = (event) => {
		const date = event.target.value
		dispatch(setResetDate(date))
	}

	const handleMessageChange = (event) => {
		const message = event.target.value
		dispatch(setMessage(message))
	}

	const cancelEditSettings = () => {
		dispatch(getPatientData({slpId: user.data.id, patientId: id}))
		setEditMode(false)
	}

	return (
		<div className={`assigned-exercises--box ${editMode ? 'border-red' : ''} p-4`}>
			<Row xs='auto'>
				<Col className='flex-grow-1'>
					<p className='fw-bold'>{t('edit_patient.settings.practice_period_title')}</p>
				</Col>
				{!editMode
					? <Col>
						<Button
							className='exercise-library--secondary-button'
							onClick={() => setEditMode(true)} variant='outline-dark'
							data-cy='edit_user_settings_button'
						>
							{t('edit_patient.settings.edit_button')}
						</Button>
					</Col>
					: <></>
				}
			</Row>

			<Form onSubmit={saveUserSettingsEvent} name='default' autoComplete="off">
				<Row className='mb-2'>
					<Col>
						<Row>
							<Form.Group>
								<OverlayTrigger
									key={'reset-date-tooltip'}
									trigger={['hover', 'focus']}
									placement='right'
									overlay={
										<Tooltip id={'reset-date-tooltip'}>
											{t('edit_patient.exercises.change_resetdate_modal.tooltip')}
										</Tooltip>
									}
								>
									<Form.Text>
										{t('edit_patient.settings.reset_date_header')}
										<FontAwesomeIcon icon={faQuestionCircle} className='mx-1'/>
									</Form.Text>
								</OverlayTrigger>
								<Form.Control
									type='date'
									required={true}
									value={patient.settings.resetDate.substring(0,10)}
									onChange={handleResetDateChange}
									max={new Date().toISOString().substring(0,10)}
									min={patient.createdAt ? patient.createdAt.substring(0,10): '2020-01-01'}
									id='user_resetdate'
									data-cy='user_resetdate'
									disabled={!editMode}
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="mt-3 mb-3" controlId='assignedExercises'>
								<Form.Check
									onChange={handleAssignedExercisesChange}
									checked={patient.settings.assignedExercises}
									type='switch'
									id='assigned_exercises'
									data-cy='assigned_exercises_button'
									label={t('edit_patient.settings.assigned_exercises_slider')}
									disabled={!editMode}
								/>
							</Form.Group>
						</Row>
					</Col>
					<Col>
						<Form.Group>
							<Form.Text>{t('edit_patient.settings.message_header')}</Form.Text>
							<Form.Control
								type='text'
								as='textarea'
								rows={editMode ? 3 : 1}
								name='user_message'
								value={patient.message}
								onChange={handleMessageChange}
								id='user_message'
								data-cy='user_message'
								disabled={!editMode}
							/>
						</Form.Group>
					</Col>
				</Row>
				{!patient.settings.assignedExercises
					? <Row>
						<p className=''>{t('edit_patient.exercises.assigned_exercises_subtitle')}</p>
					</Row>
					:<></>
				}
				{/* TODO: add this back when settings are implemented on patient side
                <Form.Group className="mb-3" controlId='autoPlayAudio'>
                    <Form.Check
                        onChange={handleAutoPlayAudioChange}
                        checked={patient.settings.autoPlayAudio}
                        type='switch'
                        id='auto_play_audio'
                        label={t("edit_patient.settings.autoplay_audio_slider")}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId='textSize'>
                    <Form.Text>{t("edit_patient.settings.text_size_title")}</Form.Text>
                    <Form.Select name="textSize" defaultValue={patient.settings.textSize} onChange={handleTextSizeChange}>
                        <option value="small">{t("edit_patient.settings.text_size_small")}</option>
                        <option value="normal">{t("edit_patient.settings.text_size_normal")}</option>
                        <option value="large">{t("edit_patient.settings.text_size_large")}</option>
                    </Form.Select>
                </Form.Group> */}
				{editMode
					? <Row xs='auto'>
						<Col className='flex-grow-1'></Col>
						{patient.settings.edited
							? <>
								<Col className='float-end text-end'>
									<Button data-cy='cancel_save_user_settings' onClick={() => cancelEditSettings()} className='exercise-library--secondary-button' variant='outline-dark'>
										{t('edit_patient.settings.cancel_button')}
									</Button>
								</Col>
								<Col className='float-end text-end'>
									<Button data-cy='save_user_settings' type="submit">
										{t('edit_patient.settings.save_button')}
									</Button>
								</Col>
							</>
							:<></>
						}
					</Row>
					: <></>
				}
			</Form>
			{showUserMessageConfirmModal
				? <ConfirmUserMessageModal
					setShowModal={setShowUserMessageConfirmModal}
					saveUserSettings={saveUserSettings}
				/>
				: <></>
			}
		</div>
	)
}

export default PracticePeriodSettings