import React from 'react'
import { motion } from 'framer-motion'
import './ExerciseFeedback.css'

interface Props {
    image: string
    success: boolean
}

const FadeInImage = ({ image, success }: Props) => {

	const getRotation = () => {
		if (!success) return [0, -10, 10, -10, 10, 0]
		else return 0
	}

	return (
		<motion.img
			src={image}
			alt={'Dialo avatar giving feedback'}
			initial={{ opacity: 0, scale: 0.8, rotate: 0 }}
			animate={{ opacity: 1, scale: 1, rotate: getRotation() }}
			transition={{ duration: 2 }}
			className='exercise--feedback-image'
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
		/>
	)
}

export default FadeInImage