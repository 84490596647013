import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Dispatch } from 'types/Types'

import { styled, Theme, CSSObject, createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Badge from '@mui/material/Badge'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import TableChartIcon from '@mui/icons-material/TableChart'
import ContactsIcon from '@mui/icons-material/Contacts'
import ImageIcon from '@mui/icons-material/Image'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import SmartToyIcon from '@mui/icons-material/SmartToy'

import ConfirmLogoutModal from 'components/ConfirmLogoutModal'

import { userIsAdmin, userIsSlp } from 'utils/helpers'
import dialogLogo from 'dialog_logo_inverted_text_purple.png'
import dialogCollapsedLogo from 'images/logos/menu_logo.png'
import './LeftNavBar.css'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		})
	})
)

interface Props {
	page: React.ReactNode,
	fullWidth: boolean
}

const LeftNavBar = ({ page, fullWidth=false }: Props) => {
	const { t, i18n } = useTranslation()
	const user = useAppSelector(state => state.user)
	const navigate = useNavigate()
	const dispatch = useDispatch<Dispatch>()
	const [activeTab, setActiveTab] = useState('dashboard')
	const [showLogoutModal, setShowLogoutModal] = useState(false)

	useEffect(() => {
		/**
			 * /dashboard
			 * /edit
			 * /edit_exercises
			 * exercises/view
			 * /patients
			 * /images
			 * /user_profile
			 * /all_users
		*/
		setActiveTab(window.location.pathname.split('/')[1])
	}, [])

	const theme = createTheme({
		palette: {
			primary: {
				main: '#ffffff'
			}
		}
	})

	const [open, setOpen] = React.useState(true)

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	/**
	 * Function that is called from the logout modal when it is closed
	 */
	const handleCloseLogoutModal = () => {
		setShowLogoutModal(false)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	}

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<Drawer variant="permanent" open={open} data-cy='main_navbar'>
					<DrawerHeader
						sx={{
							justifyContent: open ? 'center': 'inherit',
							backgroundColor: theme.palette.primary.main,
							pt: 3
						}}
					>
						{open ?
							<>
								<img
									src={dialogLogo}
									width="130"
									className='px-2'
									alt="Dialog logo"
								/>
								<IconButton onClick={handleDrawerClose} data-cy='navbar_icon_drawer_expanded'>
									<ChevronLeftIcon />
								</IconButton>
							</>
							:
							<IconButton
								onClick={handleDrawerOpen}
								sx={{ p: 0 }}
								data-cy='navbar_iconbutton_collapsed'
							>
								<img
									src={dialogCollapsedLogo}
									alt="Dialog collapsed thumbnail"
								/>
							</IconButton>
						}
					</DrawerHeader>
					<List>
						<ListItem
							key='dashboard'
							onClick={() => setActiveTab('dashboard')}
							disablePadding sx={{ display: 'block' }}
							className={activeTab === 'dashboard' ? 'navbar--item-active' : ''}
							data-cy='navbar_listitem'
						>
							<ListItemButton component={Link} to='/dashboard'
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								/* "&:hover": { color: "blue" } */
								}}
								className={activeTab === 'dashboard' ? 'navbar--item-button-active' : 'navbar--item'}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<HomeRoundedIcon className={activeTab === 'dashboard' ? 'navbar--item-active' : ''}/>
								</ListItemIcon>
								<ListItemText
									primary={t('navigation.dashboard_title')}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
						<ListItem
							key='exercise-library'
							onClick={() => setActiveTab('edit_exercises')}
							disablePadding sx={{ display: 'block' }}
							className={
								['edit_exercises', 'exercises', 'edit'].includes(activeTab)
									? 'navbar--item-active'
									: ''
							}
							data-cy='navbar_listitem'
						>
							<ListItemButton component={Link} to='/edit_exercises' data-cy="edit_exercises_page"
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className={
									['edit_exercises', 'exercises', 'edit'].includes(activeTab)
										? 'navbar--item-button-active'
										: 'navbar--item'
								}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<TableChartIcon
										className={
											['edit_exercises', 'exercises', 'edit'].includes(activeTab)
												? 'navbar--item-active'
												: ''
										}
									/>
								</ListItemIcon>
								<ListItemText primary={t('navigation.edit_exercise')} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
						{ userIsSlp(user.data) ?
							<ListItem
								key='patients'
								onClick={() => setActiveTab('patients')}
								disablePadding sx={{ display: 'block' }}
								className={activeTab === 'patients' ? 'navbar--item-active' : ''}
								data-cy='navbar_listitem'
							>
								<ListItemButton component={Link} to='/patients'
									sx={{
										minHeight: 48,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
									}}
									className={activeTab === 'patients' ? 'navbar--item-button-active' : 'navbar--item'}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<ContactsIcon
											className={activeTab === 'patients' ? 'navbar--item-active' : ''}
										/>
									</ListItemIcon>
									<ListItemText
										primary={t('navigation.patient_list')}
										sx={{ opacity: open ? 1 : 0 }}
									/>
								</ListItemButton>
							</ListItem>
							: <></>
						}
						<ListItem
							key='images'
							onClick={() => setActiveTab('images')}
							disablePadding sx={{ display: 'block' }}
							className={activeTab === 'images' ? 'navbar--item-active' : ''}
							data-cy='navbar_listitem'>
							<ListItemButton component={Link} to='/images'
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className={activeTab === 'images' ? 'navbar--item-button-active' : 'navbar--item'}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<ImageIcon className={activeTab === 'images' ? 'navbar--item-active' : ''} />
								</ListItemIcon>
								<ListItemText primary={t('navigation.images')} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
						{ userIsAdmin(user.data) ?
							<ListItem
								key='all_users'
								onClick={() => setActiveTab('users')}
								disablePadding sx={{ display: 'block' }}
								className={activeTab === 'users' ? 'navbar--item-active' : ''}
								data-cy='navbar_listitem'
							>
								<ListItemButton component={Link} to='/users'
									sx={{
										minHeight: 48,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
									}}
									className={activeTab === 'users' ? 'navbar--item-button-active' : 'navbar--item'}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<PersonSearchIcon
											className={activeTab === 'users' ? 'navbar--item-active' : ''}
										/>
									</ListItemIcon>
									<ListItemText
										primary={t('navigation.all_users')} sx={{ opacity: open ? 1 : 0 }}
									/>
								</ListItemButton>
							</ListItem>
							: <></>
						}
						<ListItem
							key='profile'
							onClick={() => setActiveTab('user_profile')}
							className={activeTab === 'user_profile' ? 'navbar--item-active' : ''}
							disablePadding sx={{ display: 'block'}}
							data-cy='navbar_listitem'
						>
							<ListItemButton component={Link} to='/user_profile'
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className={activeTab === 'user_profile' ? 'navbar--item-button-active' : 'navbar--item'}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<ManageAccountsIcon
										className={activeTab === 'user_profile' ? 'navbar--item-active' : ''}
									/>
								</ListItemIcon>
								<ListItemText primary={t('navigation.my_account')} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
						{i18n.language === 'sv' // remove operation when chatbot is available in more languages
							? <ListItem
								key='chatbot'
								onClick={() => setActiveTab('chatbot')}
								disablePadding sx={{ display: 'block' }}
								className={activeTab === 'chatbot' ? 'navbar--item-active' : ''}
								data-cy='navbar_listitem'
							>
								<ListItemButton component={Link} to='/chatbot'
									sx={{
										minHeight: 48,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
										/* "&:hover": { color: "blue" } */
									}}
									className={activeTab === 'chatbot' ? 'navbar--item-button-active' : 'navbar--item'}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<SmartToyIcon className={activeTab === 'chatbot' ? 'navbar--item-active' : ''}/>
										<Badge
											badgeContent='beta'
											color='info'
											sx={{
												scale: '0.5'
											}}
										>
										</Badge>
									</ListItemIcon>
									<ListItemText
										primary={t('Dialog Chatbot')}
										sx={{ opacity: open ? 1 : 0 }}
									/>
								</ListItemButton>
							</ListItem>
							: <></>
						}
						<ListItem
							key='logout' disablePadding
							sx={{
								display: 'block'
							}}
							data-cy='navbar_listitem'
						>
							<ListItemButton onClick={() => setShowLogoutModal(true)}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className='navbar--item'
								data-cy='navbar_listitem_logout'
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText
									primary={t('edit_user.logout')}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					</List>
				</Drawer>
				<Box component="main" sx={{ flexGrow: 1, p: fullWidth ? 0 : 3 }}>
					{page}
					{showLogoutModal
						? <ConfirmLogoutModal
							handleClose={handleCloseLogoutModal}
						/>
						: <></>
					}
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default LeftNavBar