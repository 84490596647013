import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Answers from './Answers'

interface Props {
    show:boolean
    handleClose:() => void
}

const AnswerModal = ({ show, handleClose }: Props) => {
	const { t } = useTranslation()

	return (
		<Modal
			show={show}
			keyboard={false}
			centered>
			<Modal.Body>
				<Answers />
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="outline-dark"
					className='exercise-library--secondary-button'
					data-cy='audio_processing_cancel_button'
					onClick={handleClose}
				>
					{t('patient_statistics.close_statistics_modal')}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AnswerModal