import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import React, { useEffect, useState, useRef } from 'react'
import { Form, Button, Alert, Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import { changeCreateLoading } from 'reducers/appStateSlice'
import userService from 'services/users'
import { userIsAdmin, getLanguageCode } from 'utils/helpers'
import VerifyAccount from 'components/VerifyAccount'
import ConfirmUserCreationModal from 'components/ConfirmUserCreationModal'

// TODO: New recommended package is amplify, linked from official lib:  https://www.npmjs.com/package/amazon-cognito-identity-js
import { CognitoUserAttribute, NodeCallback } from 'amazon-cognito-identity-js'
import adminUserPool from 'utils/adminUserPool'
import patientUserPool from 'utils/patientUserPool'

const organizations = {
	'fi-FI': {
		'hus': 'HUS',
		'laakso': 'Laakson sairaala',
		'neurotiimi': 'Neurotiimi',
		'terapiapalvelutpaula': 'Terapiapalvelut Paula Nylander',
		'dialog': 'Dialog',
		'other': 'Muu'
	},
	'sv-SE': {
		'afasiforeningen': 'Afasiföreningen Stockholm',
		'karolinska': 'Karolinska Institutet',
		'danderyd': 'Danderyds Sjukhus',
		'regionstockholm': 'Region Stockholm',
		'dialog': 'Dialog',
		'other': 'Andra'
	},
	'en-US': {
		'dialog': 'Dialog',
		'other': 'Other'
	}
}

const CreateUserPage = () => {
	const { t, i18n } = useTranslation()
	const [createAdmin, setCreateAdmin] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [message, setMessage] = useState('')
	const [messageVariant, setMessageVariant] = useState('warning')
	const [verifyProcess, setVerifyProcess] = useState(false)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [nrOfCharacters, setNrOfCharacters] = useState(6)
	const [modalOpen, setModalOpen] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [usernameAlertMessage, setUsernameAlertMessage] = useState<string>(t('create_user.username_message'))
	const [passwordAlertMessage, setPasswordAlertMessage] = useState<string>(t('create_user.password_message'))
	const [showMismatchAlert, setShowMismatchAlert] = useState(false)
	const [showPasswordAlert, setShowPasswordAlert] = useState(true)
	const [showUsernameAlert, setShowUsernameAlert] = useState(true)

	const [variant, setVariant] = useState('secondary')

	const eventRef = useRef({} as any)

	const dispatch = useDispatch()

	const user = useAppSelector(state => state.user)

	useEffect(() => {
		createAdmin ? setNrOfCharacters(8) : setNrOfCharacters(6)
	}, [createAdmin])

	useEffect(() => {
		validateInputFields()
	}, [username,password,confirmPassword])

	useEffect(() => {
		const passwordsMatch = checkPassword(password,confirmPassword)
		if (!passwordsMatch){
			setShowMismatchAlert(true)
		}
		else {
			setShowMismatchAlert(false)
		}
	}, [password,confirmPassword])

	const handleChange = (event) => {
		setCreateAdmin(event.target.checked)
	}

	const checkPassword = (pw1, pw2) => pw1 === pw2

	const getUserData = event => {
		const userData = new FormData()
		if (createAdmin) {
			userData.append('type', event.target.type.value)
			userData.append('organisation', event.target.organisation.value)
		} else {
			const isAdult = event.target.practiceUserCategory.value === 'child' ? 'false' : 'true'
			userData.append('isAdult', isAdult)
			userData.append('parentAccount', user.data.id)
		}
		return userData
	}

	const handleOpen = async event => {
		eventRef.current = event
		event.preventDefault()
		userIsAdmin(user.data) ? await addUser() : setModalOpen(true)
	}

	const handleClose = () => setModalOpen(false)

	const validateUsername = (username) =>{
		const usernameRegex = /^[a-z0-9_.\-åäö]{1,25}$/
		if (!usernameRegex.test(username)) {
			setUsernameAlertMessage(t('create_user.username_message'))
			return false
		}
		setUsernameAlertMessage('')
		return true
	}

	const validatePassword = (password) =>{
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{6,30}$/
		if (!passwordRegex.test(password)) {
			setPasswordAlertMessage(t('create_user.password_message'))
			return false
		}
		setPasswordAlertMessage('')
		return true
	}

	const validateInputFields = () =>{
		const usernameValidated = validateUsername(username)
		setShowUsernameAlert(!usernameValidated)
		const passwordValidated = validatePassword(password)
		setShowPasswordAlert(!passwordValidated)
		const confirmPasswordValidated = checkPassword(password, confirmPassword)
		if (usernameValidated && passwordValidated) {
			if (confirmPasswordValidated) {
				setVariant('primary')
				setDisabled(false)
			}
			else {
				setVariant('secondary')
				setDisabled(true)
			}
		}
		else {
			setVariant('secondary')
			setDisabled(true)
		}
	}

	const addUser = async () => {
		const event = eventRef.current
		setModalOpen(false)
		setShowMessage(false)
		try {
			dispatch(changeCreateLoading(true))
			const attributeList: CognitoUserAttribute[] = []
			const username = event.target.username.value
			const password = event.target.password.value
			const email = event.target.email.value.toLowerCase().trim()
			attributeList.push(new CognitoUserAttribute({
				Name: 'email',
				Value: email
			}))
			if (createAdmin) {
				const firstname = event.target.firstname.value.toLowerCase().trim()
				const lastname = event.target.lastname.value.toLowerCase().trim()
				attributeList.push(
					new CognitoUserAttribute({
						Name: 'family_name',
						Value: lastname,
					})
				)
				attributeList.push(
					new CognitoUserAttribute({
						Name: 'given_name',
						Value: firstname,
					})
				)
			}
			const pool = createAdmin ? adminUserPool : patientUserPool
			const cognitoData = await new Promise<any>((resolve, reject) =>
				pool.signUp(username, password, attributeList, [], (err, data) => {
					if (err) {
						reject(err)
					} else {
						resolve(data)
					}
				})
			)
			const userData = getUserData(event)
			userData.append('cognitoId', cognitoData.userSub)
			userData.append('username', username)
			if (createAdmin) {
				await userService.registerAdminUser(userData)
			} else {
				await userService.registerUser(userData)
			}
			dispatch(changeCreateLoading(false))
			setSuccessMessage(`${t('create_user.success')}: ${username}`)
			setVerifyProcess(true)
		} catch (err) {
			let tempErr = err
			console.error('error signing up: ', err)
			if (err == 'UsernameExistsException: User already exists'){
				tempErr = t('create_user.already_exist')
			}

			dispatch(changeCreateLoading(false))
			setErrorMessage(`${t('create_user.error')}: ${tempErr}`)
		}
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	}

	const setSuccessMessage = message => {
		setMessage(message)
		setShowMessage(true)
		setMessageVariant('success')
		setVerifyProcess(false)
	}

	const setErrorMessage = err => {
		setMessage(err)
		setShowMessage(true)
		setMessageVariant('danger')
	}

	/**
     * Available organizations given the current language and corresponding language code 
     * @returns object containing organizations available in the current language
     */
	const getOrganizations = () => organizations[getLanguageCode(i18n.language)]

	/**
     * When the page hook's value is changed updates the view content accordingly
     * @returns {JSX.element} Selected component
    */
	const adminFields = () => {
		return (
			<>
				<Form.Group className="mb-3" controlId='type'>
					<Form.Text>{t('create_user.user_type.text')}</Form.Text>
					<Form.Select name="type">
						<option value="slp">{t('create_user.user_type.options.slp')}</option>
						<option value="admin">{t('create_user.user_type.options.admin')}</option>
					</Form.Select>
				</Form.Group>
				<Form.Group className="mb-3" controlId='organisation'>
					<Form.Text>{t('create_user.organisation')}</Form.Text>
					<Form.Select name="organisation">
						{Object.keys(getOrganizations()).map(key =>
							<option key={key} value={key}>{getOrganizations()[key]}</option>
						)}
					</Form.Select>
				</Form.Group>
			</>
		)
	}

	/**
     * When the page hook's value is changed updates the view content accordingly
     * @returns {JSX.element} Selected component
    */
	const adminName = () => {
		return (
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId='firstname'>
						<Form.Text>{t('create_user.firstname')}</Form.Text>
						<Form.Control
							type="text" className="" name='firstname' placeholder={t('create_user.firstname')}
							minLength={1} maxLength={200} autoComplete='off' required
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="mb-3" controlId='lastname'>
						<Form.Text>{t('create_user.lastname')}</Form.Text>
						<Form.Control
							type="text" className="" name='lastname' placeholder={t('create_user.lastname')}
							minLength={1} maxLength={200} autoComplete='off' required
						/>
					</Form.Group>
				</Col>
			</Row>
		)
	}

	const userNamingConventionRecommendation = () => {
		return (
			<Alert variant='success' data-cy='create_user_username_naming_recommendation' >
				<b>{t('create_user.confirm_username.guidelines.title')}</b>
				<hr />
				<ul>
					<li className='mb-2'>
						<span>
							<b>{t('create_user.confirm_username.guidelines.point_1.bold')}</b>
						</span>
						<span>
							{t('create_user.confirm_username.guidelines.point_1.text')}
						</span>
					</li>
					<li>
						<span>
							<b>{t('create_user.confirm_username.guidelines.point_2.bold')}</b>
						</span>
						<span>
							{t('create_user.confirm_username.guidelines.point_2.text')}
						</span>
					</li>
				</ul>
			</Alert>
		)
	}

	const userNamingConventionAlert = () => {
		return (
			<Alert variant='danger' data-cy='create_user_username_naming_warning' >
				<p>{t('create_user.confirm_username.info_1')}</p>
				<b>{t('create_user.confirm_username.info_2')}</b>
			</Alert>
		)
	}

	return (
		<Container>
			<h2>{t('create_user.title')}</h2>
			<hr />
			{userIsAdmin(user.data)
				? <p>{t('create_user.subtitle_admin')}</p>
				: <p>{t('create_user.subtitle_slp')}</p>
			}
			{showMessage ? <Alert variant={messageVariant}>{message}</Alert> : null }
			{verifyProcess
				? <VerifyAccount
					userPool={createAdmin ? adminUserPool : patientUserPool}
					username={username}
					setUsername ={setUsername}
					setPassword ={setPassword}
					setConfirmPassword ={setConfirmPassword}
					setSuccessMessage={setSuccessMessage}
					setErrorMessage={setErrorMessage}
				/>
				: <Form onSubmit={handleOpen} name='default' autoComplete="off">
					{ userIsAdmin(user.data)
						? <Form.Group className="mb-3" controlId='adminUser'>
							<Form.Check
								onChange={handleChange}
								checked={createAdmin}
								type='switch'
								id='admin-user'
								label={t('create_user.admin_slider')}
							/>
						</Form.Group>
						: <></>
					}
					{createAdmin
						? <></>
						: <Form.Group className="mb-3" controlId='practiceUserCategory'>
							<Form.Text>{t('create_user.practice_user_category.text')}</Form.Text>
							<Form.Select name="practiceUserCategory" className='w-50'>
								<option value="child">{t('create_user.practice_user_category.options.child')}</option>
								<option value="adult">{t('create_user.practice_user_category.options.adult')}</option>
							</Form.Select>
						</Form.Group>
					}
					<Form.Group className="mb-3" controlId='email'>
						<Form.Text>{t('create_user.email')}</Form.Text>
						{ userIsAdmin(user.data) ?
							<Form.Control
								type="email" className="" name='email' placeholder={t('create_user.email')}
								minLength={1} maxLength={200} autoComplete='off' required
							/>
							: <Form.Control
								type="email" className="" name='email' value={user.data.email}
								minLength={1} maxLength={200} autoComplete='off' disabled
							/>
						}
					</Form.Group>
					{!createAdmin
						? userNamingConventionRecommendation()
						: <></>
					}
					<Form.Group className="mb-3" controlId='username'>
						<Form.Text>{t('create_user.username')}</Form.Text>
						<Form.Control
							type="text"
							className=""
							name='username'
							placeholder={t('create_user.username')}
							minLength={1}
							maxLength={200}
							autoComplete='off'
							required
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</Form.Group>
					{showUsernameAlert
						? <Alert variant='warning' data-cy='create_user_username_alert'>
							{usernameAlertMessage}
						</Alert>
						: <></>
					}
					{createAdmin ? adminName() : <></>}
					<Form.Group className="mb-3" controlId='password'>
						<Form.Text>{t('create_user.password')}</Form.Text>
						<OverlayTrigger trigger="focus" placement="bottom"
							overlay={
								<Popover>
									<Popover.Body>
										<p>{t('create_user.guidelines.nospaces') + ' ' + t('create_user.guidelines.instruction')}</p>
										<ul>
											<li>{nrOfCharacters} {t('create_user.guidelines.characters')}</li>
											<li>{t('create_user.guidelines.uppercase')}</li>
											<li>{t('create_user.guidelines.lowercase')}</li>
											<li>{t('create_user.guidelines.digit')}</li>
											{createAdmin
												? <li>{t('create_user.guidelines.symbol')} (#&_-?, … )</li>
												: <></>
											}
										</ul>
									</Popover.Body>
								</Popover>
							}
						>
							<Form.Control
								type="password"
								className=""
								name='password'
								placeholder={t('create_user.password')}
								minLength={1}
								maxLength={200}
								autoComplete='off'
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</OverlayTrigger>
					</Form.Group>
					<Form.Group className="mb-3" controlId='confirm'>
						<Form.Text>{t('create_user.confirm')}</Form.Text>
						<Form.Control
							type="password"
							className=""
							name='confirm'
							placeholder={t('create_user.confirm')}
							minLength={1}
							maxLength={200}
							autoComplete='off'
							required
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</Form.Group>
					{createAdmin ? adminFields() : <></>}
					{showPasswordAlert
						? <Alert variant='warning' data-cy='create_user_password_alert'>
							{passwordAlertMessage}
						</Alert>
						: <></>
					}
					{showMismatchAlert
						? <Alert variant='warning' data-cy='create_user_mismatch_alert'>
							{t('create_user.mismatch')}
						</Alert>
						: <></>
					}
					<Button variant={variant} disabled={disabled} type="submit" data-cy='create_user_submit'>
						{t('create_user.save')}
					</Button>
				</Form>}
			{modalOpen
				? <ConfirmUserCreationModal
					handleClose={handleClose}
					addUser={addUser}
					userNamingConventionAlert={userNamingConventionAlert}
					username={username}
				/>
				: <></>
			}
		</Container>
	)
}

export default CreateUserPage