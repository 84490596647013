import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector} from 'stateHandling/hooks'
import { Row, Col, Image, Container } from 'react-bootstrap'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'

import PatientTotalProgressBar from 'components/PatientTotalProgressBar'
import CompletedExercisesBarChart from 'components/Statistics/CompletedExercisesBarChart'
import Loading from 'components/Loading'

import { getCompletedAttemptsCount, getFilteredUserStatistics } from 'utils/statisticsHelper'
import { TimeInterval } from 'types/StatisticTypes'

import Avatar from 'images/avatars/dialo.png'
import { PatientUser } from 'types/UserTypes'

dayjs.extend(isBetween)
dayjs.extend(isoWeek)

interface Props {
	isAdult:boolean
}

const PracticeProgress = ({ isAdult }: Props) => {
	const { t } = useTranslation()

	const user = useAppSelector(state => state.user.data) as PatientUser
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const loading = useAppSelector(state => state.appState.loading)
	const exerciseListLoading = useAppSelector(state => state.exerciseList.loading)

	const [barChartData, setBarChartData] = useState<number[]>([])
	const [timeInterval, setTimeInterval] = useState<TimeInterval>('weekly')

	useEffect(() => {
		const tempFilteredPatientStatistics = getFilteredUserStatistics(patientStatistics, user.settings.resetDate, false)
		const barChartData = getCompletedAttemptsCount(tempFilteredPatientStatistics,'period', user.settings.resetDate)
		setBarChartData(barChartData)
		setTimeInterval('period')
	}, [patientStatistics])

	return (
		<>
			{loading || exerciseListLoading
				? <Loading />
				: <>
					<h2 className='ms-1 mt-5 mb-4'>{(t('practice_tabs.progress'))}</h2>
					<Container className='p-5 practice-page--stats-box' data-cy='practice_user_stats_box'>
						<Row>
							<Col lg={8} md={8}>
								<h2 className='mt-1 mb-4'>{user.username}</h2>
								<h4 className='mb-4'>{(t('practice_progress.subtitle'))}</h4>
								<PatientTotalProgressBar/>
							</Col>

							{ !isAdult
								? <Col lg={4} md={4} data-cy='practice_user_avatar'>
									<Image src={Avatar} style={{ height: '14rem', marginTop: '1rem'}} />
								</Col>
								: <></>
							}
						</Row>
					</Container>
					<h2 className='ms-1 mt-5 mb-4'>{(t('practice_progress.statistics_title'))}</h2>
					<CompletedExercisesBarChart data={barChartData} interval={timeInterval} resetDate={user.settings.resetDate} />
				</>
			}
		</>
	)
}

export default PracticeProgress