import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'

const ExerciseDescription = () => {
	const exercise = useAppSelector(state => state.currentExercise.exercise)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)

	return (
		<Row className='justify-content-center flex-grow-1'>
			<Col className='align-self-center exercise--div-question' xs={12} sm={10} md={8}>
				<Row xs={12} className='justify-content-start flex-direction-column'>
					<Col className='align-self-start' xs={12} md={12}>
						<AudioPlayer
							buffer={exerciseAudio[exercise.id]
								? exerciseAudio[exercise.id].description.patient.audio
								: []
							}
							large={false}
							text={true}
						/>
					</Col>
					<Col className='align-self-start my-3' xs={12} md={12} data-cy='exercise_description_col'>
						{exercise.description.patient.text}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default ExerciseDescription