import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { Table, Row, Col } from 'react-bootstrap'
import LanguageIcon from '@mui/icons-material/Language'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'

import ExerciseLibraryCardIcon from 'components/ExerciseLibraryCard/ExerciseLibraryCardIcon'
import { tagColor, getAnswerFormat } from 'utils/helpers'
import { LibraryExercise } from 'types/ExerciseTypes'
import { AdminUser } from 'types/UserTypes'

import dialogLogo from 'images/logos/thumbnail.png'

interface Props {
    exercise: LibraryExercise
    baselib: boolean
    renderType: string
}

const ExercisePreviewTable = ({ exercise, baselib, renderType }: Props) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)

	/*
	Detect if the exercise is created by the current user
	Set the state-variable that controls the card header
	*/
	const ownExercise = (id: string) => {
		const adminUser = user.data as AdminUser
		return adminUser.exercises.includes(id)
	}

	/**
	 * Returns the triangle indicating base lib, own exercise or community exercise
	 * @returns { JSX.Element }
	 */
	const getTriangle = (id: string) => {
		if (baselib) {
			return (
				<div
					className='exercise-library--row-base-lib-icon'
					data-cy='exercise_preview_table_row_baselib_icon'
				>
					<img
						src={dialogLogo}
						className='icon'
						alt="Dialog logo"
					/>
					<div className='corner-box'></div>
				</div>
			)
		} else if (ownExercise(id)) {
			return (
				<div
					className='exercise-library--row-own-exercise-icon'
					data-cy='exercise_preview_table_row_my_icon'
				>
					<PersonIcon className='icon'/>
					<div className='corner-box'></div>
				</div>
			)
		}
		return (
			<div
				className='exerciseLibraryCard--row-community-icon'
				data-cy='exercise_preview_table_row_community_icon'
			>
				<LanguageIcon className='icon'/>
				<div className='corner-box'></div>
			</div>
		)
	}

	return (
		<>
			<Table responsive className='mb-5' data-cy='exercise_preview_table'>
				<thead>
					<tr>
						<th>{t('edit_patient.exercises.table.title')}</th>
						<th>{t('edit_patient.exercises.table.template')}</th>
						<th>{t('edit_patient.exercises.table.answer_format')}</th>
						<th>{t('edit_patient.exercises.table.category')}</th>
					</tr>
				</thead>
				<tbody>
					<tr key={exercise.id} className='exercise-library--row' data-cy='exercise_preview_table_row'>
						<td
							className='exercise-library--table-row-title'
							data-cy='exercise_preview_table_row_title'
						>
							<ExerciseLibraryCardIcon
								type={!exercise.isAdult && exercise.skill ? exercise.skill[0]: renderType}
								isAdult={exercise.isAdult}
							/>
							{exercise.title.text}
							{!exercise.settings.isPublic
								? <LockIcon
									data-cy='exercise_preview_private_exercise_icon'
									className='d-inline mx-2 exerciseLibraryCard--private-icon'
								/>
								: <></>
							}
						</td>
						<td>{t(`exercise_card.format.${exercise.template}`)}</td>
						<td>{t(`exercise_card.alternative_format.${getAnswerFormat(exercise, renderType)}`)}</td>
						<td>{t(`create_exercise.settings.category.${exercise.category}`)}</td>
						<td>
							<Row xs="auto">
								{exercise.subtype.map(type => {
									return (
										<Col key={type} className='m-2 exerciseLibraryCard--tag text-nowrap'
											style={{
												color: tagColor[type]
													? tagColor[type].color : tagColor.default.color,
												backgroundColor: tagColor[type]
													? tagColor[type].bgColor : tagColor.default.bgColor,
												borderColor: tagColor[type]
													? tagColor[type].borderColor : tagColor.default.borderColor
											}}
										>
											{t(`exercise_card.subtypes.${type}`)}
										</Col>
									)})
								}
							</Row>
						</td>
						<td className='exercise-library--table-row-type-col'>
							{getTriangle(exercise.id)}
						</td>
					</tr>
				</tbody>
			</Table>
		</>
	)
}

export default ExercisePreviewTable