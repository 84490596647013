import { Container, Alert, Row, Col } from 'react-bootstrap'

import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'


import { useSelector, useDispatch } from 'react-redux'
import { loginCognitoUser, setShowMessage } from 'reducers/userSlice'
import VerifyAccountModal from 'components/VerifyAccountModal'

import Logo from 'dialog_logo_inverted_text_purple.png'
import LogoSquare from 'dialog_logo_square.svg'
import InstructorLoginImage from 'images/login_instructor.svg'
import InstructorLoginImage2 from 'images/login_instructor2.svg'
import PracticeLoginImage from 'images/login_practice.svg'
import PracticeLoginImage2 from 'images/login_practice2.svg'

import LanguageSwitcher from 'components/LanguageSwitcher'
import ResetPasswordModal from 'components/ResetPasswordModal'
import patientUserPool from 'utils/patientUserPool'
import adminUserPool from 'utils/adminUserPool'

const LoginPage = () => {
	const [username, setUsername] = useState('')
	const [userTypeSelected, setUserTypeSelected] = useState(false)
	const [adminUser, setAdminUser] = useState(false)
	const [showVerifyModal, setShowVerifyModal] = useState(false)
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)


	const message = useSelector(state => state.user.status.message)
	const userConfirmed = useSelector(state => state.user.status.confirmed)
	const forgotPassword = useSelector(state => state.user.status.forgotPassword)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const submit = event => {
		event.preventDefault()
		const formUsername = event.target.username.value
		const formPassword = event.target.password.value
		setUsername(formUsername)
		const userCred = { username: formUsername, password: formPassword, adminPool: adminUser }
		dispatch(loginCognitoUser(userCred))
	}

	const openVerifyModal = async event => {
		event.preventDefault()
		setShowVerifyModal(true)
		dispatch(setShowMessage({ showMessage: false, message: '', variant: 'warning' }))
	}

	const openResetPasswordModal = async event => {
		event.preventDefault()
		setShowResetPasswordModal(true)
		dispatch(setShowMessage({ showMessage: false, message: '', variant: 'warning' }))
	}

	const handleClose = () => {
		setShowVerifyModal(false)
		setShowResetPasswordModal(false)
	}

	const setPatientLogin = () => {
		setUserTypeSelected(true)
		dispatch(setShowMessage({ showMessage: false, message: '', variant: 'warning' }))
	}

	const setTherapistLogin = () => {
		setUserTypeSelected(true)
		setAdminUser(true)
		dispatch(setShowMessage({ showMessage: false, message: '', variant: 'warning' }))
	}

	return (
		<div className='d-flex flex-column login--body' style={{ height: '100vh' }}>
			<header className='d-flex pt-5 px-5 pb-3'>
				<LanguageSwitcher getExercises={false} />
				{userTypeSelected
					? <div className='ms-auto' onClick={() => setAdminUser(!adminUser)} style={{ cursor: 'pointer' }}>
						<div className='ms-5'>
							<FontAwesomeIcon className='me-2' icon={faUser} style={{ color: '#9E78ED' }} />
							{adminUser ? t('login.change_user_type_therapist') : t('login.change_user_type_patient')}
						</div>
					</div>
					: <Box component='img' src={Logo} className='ms-auto' style={{ width: '9rem' }} />
				}
			</header>
			<Container className='d-flex justify-content-center align-items-center'>
				{showVerifyModal ?
					<VerifyAccountModal
						username={username}
						handleClose={handleClose}
						userPool={adminUser ? adminUserPool : patientUserPool}
					/>
					: <></>}
				{showResetPasswordModal ?
					<ResetPasswordModal
						username={username}
						handleClose={handleClose}
						userPool={adminUser ? adminUserPool : patientUserPool}
					/>
					: <></>}
				<div className='d-flex flex-column justify-content-center align-items-center mb-3'>
					{userTypeSelected
						? <>
							{message.showMessage ? <Alert variant={message.variant}>{message.message}</Alert> : <></> }
							<div className="d-flex flex-column align-items-center">
								{adminUser
									? <>
										<Box
											component='img'
											src={InstructorLoginImage2}
											className='mb-4'
											sx={{
												height: '9rem'
											}}
										/>
										<h3 className='mb-4'>{t('login.login_title_therapist')}</h3>
									</>
									: <>
										<Box
											component='img'
											src={PracticeLoginImage2}
											className='mb-4'
											sx={{
												height: '9rem'
											}}
										/>
										<h3 className='mb-4'>{t('login.login_title_patient')}</h3>
									</>

								}
							</div>
						</>
						: <Box component='img'
							src={LogoSquare}
							sx={{
								width: '5rem'
							}}
						/>
					}
					{userTypeSelected
						? <>
							<Box component='form' className='d-flex flex-column mb-2' onSubmit={submit}>
								<FormGroup>
									<FormLabel className='form-text'>{t('login.username')}</FormLabel>
									<TextField
										id="username"
										autoComplete='off'
										type="text"
										name="username"
										placeholder={t('login.username')}
										className='mb-3'
										sx={{
											width: '25rem'
										}}
									/>
									<FormLabel className='form-text'>{t('login.password')}</FormLabel>
									<TextField
										id="password"
										name="password"
										type="password"
										placeholder={t('login.password')}
										className='mb-4'
										sx={{
											width: '25rem'
										}}
									/>
								</FormGroup>
								<Button
									data-cy={'login_button'}
									type='submit'
									variant='outlined'
									size='large'
									className='mb-2'
									sx={{
										textTransform: 'none',
										background: '#53389E',
										color: '#FFFFFF',
										border: 'none',
										'&:hover': {
											background: 'rgba(83, 56, 158, 0.9)',
											border: 'none',
										}
									}}
								>
									{t('login.login')}
								</Button>
								<div className={!userConfirmed || forgotPassword ? 'mb-0' : 'mb-5'}>
									{userConfirmed
										? <></>
										: <Button
											variant='outlined'
											size='large'
											onClick={openVerifyModal}
											sx={{
												textTransform: 'none',
												width: '100%',
												color: '#53389E',
												borderColor: '#53389E',
												'&:hover': {
													background: 'rgba(83, 56, 158, 0.1)',
												}
											}}
										>
											{t('login.verify_account')}
										</Button>}
									{forgotPassword
										? <Button
											variant='outlined'
											size='large'
											onClick={openResetPasswordModal}
											sx={{
												textTransform: 'none',
												width: '100%',
												color: '#53389E',
												borderColor: '#53389E',
												'&:hover': {
													background: 'rgba(83, 56, 158, 0.1)',
												}
											}}
										>
											{t('login.reset_password')}
										</Button>
										: <></>}
								</div>
							</Box>
						</>
						: <>
							<h3 className='text-center mt-4 mb-5 pb-2'>{t('login.account_type_select')}</h3>
							<Row className='mb-5 mt-2'>
								<Col className='d-flex justify-content-center align-items-center m-2'>
									<Button
										onClick={setPatientLogin}
										className='d-flex flex-column'
										data-cy='patient_login_select'
										sx={{
											fontSize: '1.2rem',
											textTransform: 'none',
											color: '#212529',
											border: '1px solid #D6BBFB',
											width: '24rem',
											height: '17rem',
											'&:hover': {
												background: 'rgba(158, 120, 237, 0.1)',
												border: '1px solid #D6BBFB'
											}
										}}
									>
										<Box
											component='img'
											src={PracticeLoginImage}
											className='mb-4'
											sx={{
												height: '9rem'
											}}
										/>
										<p className='m-0'>{t('login.patient_account')}</p>
									</Button>
								</Col>
								<Col className='d-flex justify-content-center align-items-center m-2'>
									<Button
										onClick={setTherapistLogin}
										className='d-flex flex-column'
										data-cy='therapist_login_select'
										sx={{
											fontSize: '1.2rem',
											textTransform: 'none',
											color: '#212529',
											border: '1px solid #D6BBFB',
											width: '24rem',
											height: '17rem',
											'&:hover': {
												background: 'rgba(158, 120, 237, 0.1)',
												border: '1px solid #D6BBFB'
											}
										}}
									>
										<Box
											component='img'
											src={InstructorLoginImage}
											className='mb-4'
											sx={{
												height: '9rem'
											}}
										/>
										<p className='m-0'>{t('login.therapist_account')}</p>
									</Button>
								</Col>
							</Row>
						</>
					}
				</div>
			</Container>
			<footer
				className='d-flex justify-content-center align-items-top'
				style={{
					background: 'rgba(158, 120, 237, 0.1)',
					flexGrow: 1,
					paddingTop: 30
				}}
			>
				{userTypeSelected
					? <>
						<Link color='inherit' className='m-2' href='https://www.dialog-therapy.com/terms-conditions' target='_blank' rel='noopener noreferrer'>{t('login.footer.terms_and_conditions')}</Link>
						<Link color='inherit' className='m-2' href='https://app.dialog-therapy.com/cookies' target='_blank' rel='noopener noreferrer'>{t('login.footer.cookies')}</Link>
					</>
					: null
				}

			</footer>
		</div>
	)
}

export default LoginPage