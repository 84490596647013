import React from 'react'
import { useTranslation } from 'react-i18next'
import { AWS_URL } from 'utils/config'
import { StyleSheet, View, Text, Image as PdfImage } from '@react-pdf/renderer'

const DownloadableProductionExercise = ({ questions }) => {
	const { t } = useTranslation()

	const styles = StyleSheet.create({
		flexWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '5px' }
	})

	return (
		<>
			{Object.values(questions).map((q, i) => {
				return (
					<View key={i} style={{ border: '1px solid #000000', borderRadius: '5px', margin: '16px 5px 0 5px', padding: '5px'}} wrap={false}>
						<Text>{i+1}.</Text>
						<View style={styles.flexWrapper}>
							{q.imageURL && q.imageURL !== 'undefined'
								? <PdfImage src={AWS_URL + q.image.url}
									style={{ height: '130', borderRadius: '5px' }}
									crossOrigin='anonymous'
								></PdfImage>
								: <></>
							}
						</View>
						<View style={styles.flexWrapper}>
							<Text>
								{q.template === 'default'
									? q.question.text
									: q.sentence.text
								}
							</Text>
						</View>
						<View style={{ margin: '20px 5px 70px 5px' }}>
							{q.answerFormat === 'default' ? <Text>{t('preview_exercise.download_exercise.answer')}:</Text> : <Text>{t('preview_exercise.download_exercise.answer')}: {q.sentence.text}</Text>}
						</View>
					</View>
				)
			})}
		</>
	)
}

export default DownloadableProductionExercise