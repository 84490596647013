import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { DBStatistic } from 'types/StatisticTypes'
import { Tabs, Tab, Row, Col} from 'react-bootstrap'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import CompletedExercisesBarChart from 'components/Statistics/CompletedExercisesBarChart'
import AllTimeLineChart from 'components/Statistics/AllTimeLineChart'
import AllTimePieChart from 'components/Statistics/AllTimePieChart'
import StatisticsSummaryBox from 'components/Statistics/StatisticsSummaryBox'
import StatisticsTable from 'components/Statistics/StatisticsTable'

import { getCompletedAttemptsCount } from 'utils/statisticsHelper'
import { TimeInterval }  from 'types/StatisticTypes'
import './Statistics.css'

dayjs.extend(isBetween)
dayjs.extend(isoWeek)

interface DataPoint {
	month: string;
	count: number;
  }

/**
 * Aggregates and counts the total number of completed exercises per month.
 * @param statistics - An array of DBStatistic objects containing exercise attempts.
 * @returns An array of objects, each with a 'month' (formatted as 'YYYY-MM') and a 'count' representing
 *          the total number of completed exercises for that month, sorted in ascending order by month.
 */
const getYearMonthExerciseData = (statistics: DBStatistic[]) => {
	const monthlyData: Record<string, number> = {}

	statistics.forEach(statistic => {
		statistic.attempts.forEach(attempt => {
			const endDate = dayjs(attempt.end)
			const yearMonth = endDate.format('YYYY-MM') // Format date as 'YYYY-MM'

			if (!monthlyData[yearMonth]) {
				monthlyData[yearMonth] = 0
			}

			monthlyData[yearMonth] += 1 // Increment count for this month
		})
	})

	// Convert the aggregated data into an array of objects
	return Object.entries(monthlyData).map(([month, count]) => ({
		month,
		count
	})).sort((a, b) => dayjs(a.month).isAfter(dayjs(b.month)) ? 1 : -1) // Sort by month-year
}

/**
 * Calculates the total number of attempts for each skill across all statistics for the chosen interval.
 * @param statistics - An array of DBStatistic objects containing exercise attempts and skill information.
 * @returns An array of objects, each with a 'label' (the skill name) and a 'value' representing
 *          the total number of attempts associated with that skill.
 */
function countAttemptsBySkill(statistics: DBStatistic[]): { label: string; value: number }[] {
	const skillAttemptCounts: { [skill: string]: number } = {}

	statistics.forEach(stat => {
		const skill = stat.skill[0]

		if (skill !== undefined) { // To not show any broken old statistics, for example adult exercise performed by child user
			const numberOfAttempts = stat.attempts.length

			if (skillAttemptCounts[skill]) {
				skillAttemptCounts[skill] += numberOfAttempts
			} else {
				// If the skill doesn't exist, initialize it
				skillAttemptCounts[skill] = numberOfAttempts
			}
		}
	})

	// Convert to the format needed for pie chart
	return Object.entries(skillAttemptCounts).map(([skill, count]) => ({
		label: skill,
		value: count
	}))
}

/**
 * Calculates the total number of attempts for each answer format across all statistics for the chosen interval.
 * @param statistics - An array of DBStatistic objects containing exercise attempts and answer format information.
 * @returns An array of objects, each with a 'label' (the answer format) and a 'value' representing
 *          the total number of attempts associated with that answer format.
 */
function countAttemptsByType(statistics: DBStatistic[]): { label: string; value: number }[] {
	const answerFormatCounts: { [answerFormat: string]: number } = {}

	// Count the number of attempts by answerFormat
	statistics.forEach(stat => {
		stat.attempts.forEach(attempt => {
			const answerFormat = attempt.answerFormat
			if (answerFormatCounts[answerFormat]) {
				answerFormatCounts[answerFormat] += 1
			} else {
				answerFormatCounts[answerFormat] = 1
			}
		})
	})

	// Convert to the format needed for pie chart
	return Object.entries(answerFormatCounts).map(([answerFormat, count]) => ({
		label: answerFormat,
		value: count
	}))
}

const Statistics = () => {
	const { t } = useTranslation()

	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const patientIsAdult = useAppSelector(state => state.currentPatient.isAdult)
	const patientResetDate = useAppSelector(state => state.currentPatient.settings.resetDate)

	const [filteredPatientStatistics, setFilteredPatientStatistics] = useState<DBStatistic[]>([])
	// TODO: compile separate list of the statistics of the assigned exercises
	// const assignedExercises = useAppSelector(state => state.currentPatient.settings.assignedExercises)
	const [tabIndex, setTabIndex] = useState<string>('0')
	const [barChartData,setBarChartData] = useState<number[]>([])
	const [pieChartData, setPieChartData] = useState<{ label: string; value: number }[]>([])
	const [timeInterval, setTimeInterval] = useState<TimeInterval>('weekly')
	const [lineChartData,setLineChartData] = useState<DataPoint[]>([])

	useEffect(() => {
		let tempFilteredPatientStatistics = patientStatistics

		const now = dayjs()
		const startOfWeek = now.startOf('week')
		const startOfMonth = now.startOf('month')

		if (tabIndex === '0') {
			tempFilteredPatientStatistics = Object.values(patientStatistics).map(statistic => ({
				...statistic,
				attempts: statistic.attempts.filter(attempt =>
					dayjs(attempt.end).isBetween(patientResetDate, now) && attempt.completed
				)
			}))
			// To remove any already existing instances with only non-completed attempts
				.filter(statistic => statistic.attempts.length > 0)
			setBarChartData(getCompletedAttemptsCount(tempFilteredPatientStatistics,'period', patientResetDate))
			setTimeInterval('period')
		} else if (tabIndex === '1'){
			tempFilteredPatientStatistics = Object.values(patientStatistics).map(statistic => ({
				...statistic,
				attempts: statistic.attempts.filter(attempt =>
					dayjs(attempt.end).isBetween(startOfWeek, now) && attempt.completed
				)
			}))
			// To remove any already existing instances with only non-completed attempts
				.filter(statistic => statistic.attempts.length > 0)
			setBarChartData(getCompletedAttemptsCount(tempFilteredPatientStatistics,'weekly', false))
			setTimeInterval('weekly')
		} else if (tabIndex === '2'){
			tempFilteredPatientStatistics = Object.values(patientStatistics).map(statistic => ({
				...statistic,
				attempts: statistic.attempts.filter(attempt =>
					dayjs(attempt.end).isBetween(startOfMonth, now) && attempt.completed
				)
			}))
				.filter(statistic => statistic.attempts.length > 0)
			setBarChartData(getCompletedAttemptsCount(tempFilteredPatientStatistics,'monthly', false))
			setTimeInterval('monthly')
		} else if (tabIndex === '3') {
			tempFilteredPatientStatistics = Object.values(patientStatistics).map(statistic => ({
				...statistic,
				attempts: statistic.attempts.filter(attempt => attempt.completed)
			}))
				.filter(statistic => statistic.attempts.length > 0)
			setTimeInterval('all_time')
			setLineChartData(getYearMonthExerciseData(tempFilteredPatientStatistics))

			if (patientIsAdult){
				const attemptsByType = countAttemptsByType(tempFilteredPatientStatistics)
				setPieChartData(attemptsByType)
			}
			else {
				const attemptsBySkill = countAttemptsBySkill(tempFilteredPatientStatistics)
				setPieChartData(attemptsBySkill)
			}
		}

		setFilteredPatientStatistics(tempFilteredPatientStatistics)

	}, [patientStatistics,tabIndex])

	return (
		<>
			<div className="d-flex justify-content-end mb-3" >
				<Tabs
					defaultActiveKey={tabIndex}
					className='me-5 custom-tabs'
					onSelect ={(idx) => setTabIndex(idx||'0')}
					data-cy='statistics_page_tabs'
				>
					<Tab eventKey='0' title={t('patient_statistics.tabs.period')} data-cy='statistics_period_tab'/>
					<Tab eventKey='1' title={t('patient_statistics.tabs.week')} data-cy='statistics_week_tab'/>
					<Tab eventKey='2' title={t('patient_statistics.tabs.month')} data-cy='statistics_month_tab'/>
					<Tab eventKey='3' title={t('patient_statistics.tabs.all_time') } data-cy='statistics_all_tab'/>
				</Tabs>
			</div>
			<>
				<Row>
					{tabIndex !== '3' && (
						<>
							<Col lg={3} className="mb-3 me-5 custom-statistics-border">
								<StatisticsSummaryBox filteredStatistics={filteredPatientStatistics} interval={timeInterval} />
							</Col>
							<Col lg={8} className="mb-3 custom-statistics-border">
								<CompletedExercisesBarChart data={barChartData} interval={timeInterval} resetDate={patientResetDate} />
							</Col>
						</>
					)}
					{tabIndex === '3' && (
						<Col className="mb-3 custom-statistics-border">
							<StatisticsSummaryBox filteredStatistics={filteredPatientStatistics} interval={timeInterval}/>
						</Col>
					)}
				</Row>
				{tabIndex === '3' && (
					<Row className="mb-3 custom-statistics-border" >
						<Col xs={6} sm={6} md={6} lg={3} className=" mb-3 me-5">
							<AllTimePieChart data={pieChartData} isAdult={patientIsAdult} />
						</Col>
						<Col xs={12} sm={12} md={12} lg={8} className="mb-3">
							<AllTimeLineChart data={lineChartData} />
						</Col>
					</Row>
				)}
				<StatisticsTable filteredPatientStatistics={filteredPatientStatistics}/>
			</>
		</>
	)
}


export default Statistics