import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import { faComment, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
    id: number
	selectScenario: (id: number, format: string) => Promise<void>
}

const ChatbotScenarioCard = ({ id, selectScenario}:Props) => {
	const scenarios = useAppSelector(state => state.chatbot.scenarios)

	return (
		<Card key={scenarios[id].title}>
			<Card.Body>
				<Card.Title className='chatbot--card-title'>{scenarios[id].title}</Card.Title>
				<Card.Text className='chatbot--card-text'>
					{scenarios[id].description}
				</Card.Text>
			</Card.Body>
			<Row xs='auto' className='justify-content-center'>
				<Col>
					<Button variant='outline-primary' size='lg' className='m-2 chatbot--scenario-button' onClick={() => selectScenario(id, 'speak')}>
						<FontAwesomeIcon icon={faComment} className='px-2' />
                        Tala
					</Button>
				</Col>
				<Col>
					<Button variant='outline-primary' size='lg' className='m-2 chatbot--scenario-button' onClick={() => selectScenario(id, 'write')}>
						<FontAwesomeIcon icon={faPen} className='px-2' />
                        Skriva
					</Button>
				</Col>
			</Row>
		</Card>)
}

export default ChatbotScenarioCard