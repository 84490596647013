import React from 'react'
import FadeInImage from 'components/ExerciseFeedback/FadeInImage'
import IncorrectImage from 'images/avatars/dialo_incorrect.png'


const FeedbackIncorrectChildren = () => {
	return <FadeInImage
		image={IncorrectImage}
		success={false}
	/>
}

export default FeedbackIncorrectChildren