import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { AdminUser, PatientUser, UserState, AssignedPatientUser, UserMessageState } from 'types/UserTypes'
import userService from 'services/users'
import { getUserSession } from 'utils/userSession'
import adminUserPool from 'utils/adminUserPool'
import patientUserPool from 'utils/patientUserPool'
import i18next from 'services/i18n'

interface UserCredentials {
	username: string
	password: string
	adminPool: boolean
}

export const userSession = createAsyncThunk(
	'user/userSession',
	async (_, thunkAPI) => {
		// TODO: type session
		const session = await getUserSession()
		if (!session) return thunkAPI.rejectWithValue('User session has ended. Please log in.')
		try {
			const cognitoId = session.accessToken.payload.sub
			const userData = await userService.getUserData(cognitoId)
			userData.email = session.idToken.payload.email
			userData.cognitoId = cognitoId
			userData.username = session.accessToken.payload.username
			return userData
		} catch (e) {
			return thunkAPI.rejectWithValue(i18next.t('login.error_messages.session'))
		}
	}
)

export const loginCognitoUser = createAsyncThunk(
	'user/loginCognitoUser',
	async (userCred:UserCredentials, thunkAPI) => {
		try {
			const result:any = await new Promise((resolve, reject) => {
				const pool = userCred.adminPool ? adminUserPool : patientUserPool
				const user = new CognitoUser({
					Username: userCred.username,
					Pool: pool,
				})

				const authDetails = new AuthenticationDetails({
					Username: userCred.username,
					Password: userCred.password,
				})

				user.authenticateUser(authDetails, {
					onSuccess: (result) => {
						resolve(result)
					},
					onFailure: (err) => {
						reject(err)
					},
					newPasswordRequired: (data) => {
						// TODO: handle this case -- does not work to login
						resolve(data)
					},
				})
			})
			const cognitoId = result.idToken.payload.sub
			const userData = await userService.getUserData(cognitoId)
			userData.email = result.idToken.payload.email
			userData.cognitoId = cognitoId
			return userData
		} catch (e) {
			console.error('Could not log in: ', e)
			return thunkAPI.rejectWithValue({ error: e.message, adminUser: userCred.adminPool })
		}
	}
)

export const logoutCognitoUser = createAsyncThunk(
	'user/logoutCognitoUser',
	async (adminUser:boolean, thunkAPI) => {
		try {
			const pool = adminUser ? adminUserPool : patientUserPool
			const user = pool.getCurrentUser()
			user?.signOut()
			return true
		} catch (e) {
			console.error('Could not log out user: ', e)
			return thunkAPI.rejectWithValue(
				`${i18next.t('login.error_messages.logout')} ${e.message}`
			)
		}
	}
)

export const deleteAdminUser = createAsyncThunk(
	'user/deleteAdminUser',
	async (userId:string, thunkAPI) => {
		// console.log('userId: ', userId)
		// TODO: figure out how to delete a user in Cognito and implement! 
	}
)

export const getPatientList = createAsyncThunk(
	'user/getPatientList',
	async (userId: string, thunkAPI) => {
		try {
			const patients = await userService.getPatients(userId)
			return patients
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get patients list for user: ${e.response.data}` as string)
		}
	}
)

export const updateUserPoints = createAsyncThunk(
	'user/updateUserPoints',
	async (data: FormData, thunkAPI) => {
		try {
			const practiceUser = await userService.updateUserPoints(data)
			return practiceUser
		} catch (e) {
			return thunkAPI.rejectWithValue(`The points could not be updated: ${e.response.data}` as string)
		}
	}
)

const initialState: UserState = {
	data: {
		username: '',
		cognitoId: '',
		id: '',
		type: '',
		email: '',
		createdAt: '',
		parentAccount: '',
		patients: [],
		favoriteExercises: [],
		organisation: [],
		isAdult: false,
		message: '',
		settings: {
			textSize: '',
			autoPlayAudio: false,
			assignedExercises: false,
			resetDate: ''
		},
		exercises: [],
		gamification: {
			points: 0,
			avatar: {
				image: '',
				name: '',
				extras: []
			}
		}
	},
	status: {
		message: {
			showMessage: false,
			message: '',
			variant: 'warning'
		},
		authenticated: null,
		loggedOut: false,
		confirmed: true,
		forgotPassword: false,
		patientListLoading: false
	}
}
export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setPatients: (state:UserState, action:PayloadAction<AssignedPatientUser[]>) => {
			state.data.patients = action.payload
		},
		changeFavoriteExercises: (state:UserState, action:PayloadAction<string[]>) => {
			state.data.favoriteExercises = action.payload
		},
		// TODO: exercises can either be array of string, or array of AssignedExercises ..
		addUserExerciseId: (state:UserState, action:PayloadAction<string|any>) => {
			state.data.exercises = [...state.data.exercises, action.payload]
		},
		setShowMessage: (state:UserState, action:PayloadAction<UserMessageState>) => {
			state.status.message.showMessage = action.payload.showMessage
			state.status.message.message = action.payload.message
			state.status.message.variant = action.payload.variant
		},
		setUserConfirmed: (state:UserState, action:PayloadAction<boolean>) => {
			state.status.confirmed = action.payload
		},
		setForgotPassword: (state:UserState, action:PayloadAction<boolean>) => {
			state.status.forgotPassword = action.payload
		},
		setPatientListLoading: (state:UserState, action:PayloadAction<boolean>) => {
			state.status.patientListLoading = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(loginCognitoUser.fulfilled, (state:UserState, action:PayloadAction<AdminUser|PatientUser>) => {
			state.status.message = initialState.status.message
			state.status.authenticated = true
			state.status.forgotPassword = false
			state.data = action.payload
		})
		// TODO: figure out the type of the error
		builder.addCase(loginCognitoUser.rejected, (state:UserState, action:PayloadAction<any>) => {
			if (action.payload.error === 'User is not confirmed.') {
				state.status.confirmed = false
				state.status.forgotPassword = false
				state.status.authenticated = false
				state.status.message.message = i18next.t('login.error_messages.not_confirmed')
			} else if (action.payload.error === 'Incorrect username or password.') {
				state.status.forgotPassword = true
				if (action.payload.adminUser) {
					state.status.message.message = i18next.t('login.error_messages.wrong_cred_admin')
				} else {
					state.status.message.message = i18next.t('login.error_messages.wrong_cred_patient')
				}
			}
			state.status.message.showMessage = true
			state.status.message.variant = 'danger'
		})
		builder.addCase(loginCognitoUser.pending, (state:UserState) => {
			state.status.message.showMessage = true
			state.status.message.message = i18next.t('login.status_messages.logging_in')
			state.status.message.variant = 'success'
			state.status.loggedOut = false
		})
		builder.addCase(logoutCognitoUser.fulfilled, (state:UserState) => {
			state.data = initialState.data
			state.status.message.showMessage = false
			state.status.message.message = i18next.t('login.status_messages.logged_out')
			state.status.authenticated = false
			state.status.loggedOut = true
		})
		builder.addCase(logoutCognitoUser.rejected, (state:UserState, action:PayloadAction<any>) => {
			state.status.message.showMessage = true
			state.status.message.message = action.payload
			state.status.message.variant = 'danger'
		})
		builder.addCase(userSession.fulfilled, (state:UserState, action:PayloadAction<AdminUser|PatientUser>) => {
			state.data = action.payload
			state.status.authenticated = true
		})
		builder.addCase(userSession.rejected, (state:UserState, action:PayloadAction<any>) => {
			state.status.message.showMessage = true
			if (!state.status.loggedOut) {
				state.status.message.message = action.payload
				state.status.message.variant = 'danger'
			}
			state.status.authenticated = false
		})
		builder.addCase(deleteAdminUser.fulfilled, (state:UserState, action:PayloadAction<any>) => {
			// TODO: handle!
			console.log('delete user fulfilled!')
		})
		builder.addCase(deleteAdminUser.rejected, (state:UserState, action:PayloadAction<any>) => {
			// TODO: handle!
			console.log('delete user rejected!')
		})
		builder.addCase(getPatientList.fulfilled, (state:UserState, action:PayloadAction<any>) => {
			state.data.patients = action.payload
			state.status.patientListLoading = false
		})
		builder.addCase(getPatientList.rejected, (state:UserState, action:PayloadAction<any>) => {
			state.status.message.message = action.payload
			state.status.message.variant = 'danger'
			state.status.patientListLoading = false
		})
		builder.addCase(updateUserPoints.fulfilled, (state:UserState, action:PayloadAction<any>) => {
			(state.data as PatientUser).gamification.points = action.payload.gamification.points
		})
		builder.addCase(updateUserPoints.rejected, (state:UserState, action:PayloadAction<any>) => {
			// TODO: add some error message to the user here
			console.error('Could not update the points for the user!')
		})
	}
})

export const {
	setPatients,
	addUserExerciseId,
	changeFavoriteExercises,
	setShowMessage,
	setUserConfirmed,
	setForgotPassword,
	setPatientListLoading
} = userSlice.actions

export default userSlice.reducer
