import React from 'react'
import { Image } from 'react-bootstrap'
import { filterIconColor } from 'utils/helpers'
import { EXERCISE_TYPE_SPEAK,
	EXERCISE_TYPE_WRITE,
	EXERCISE_TYPE_READ,
	EXERCISE_TYPE_LISTEN,
    SKILL_PHONOLOGY,
	SKILL_GRAMMAR,
	SKILL_UNDERSTANDING,
	SKILL_VOCABULARY,
	SKILL_PRAGMATICS
} from 'utils/config'
import { faHeadphones, faBook, faComment, faPen, faBrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PhonologyIcon from 'images/phonology.svg'
import GrammarIcon from 'images/grammar.svg'
import UnderstandingIcon from 'images/understanding.svg'
import VocabularyIcon from 'images/vocabulary.svg'

interface Props {
	type: string,
    isAdult: boolean
}

const ExerciseLibraryCardIcon = ({type, isAdult}: Props) => {

	/**
     * Get the correct icon, given the type ot skill
     */
	const getTypeIcon = () => {
		if (isAdult) {
			if (type === EXERCISE_TYPE_WRITE)
				return <FontAwesomeIcon style={{color: filterIconColor[EXERCISE_TYPE_WRITE]}} icon={faPen} />
			if (type === EXERCISE_TYPE_SPEAK)
				return <FontAwesomeIcon style={{color: filterIconColor[EXERCISE_TYPE_SPEAK]}} icon={faComment} />
			if (type === EXERCISE_TYPE_READ)
				return <FontAwesomeIcon style={{color: filterIconColor[EXERCISE_TYPE_READ]}} icon={faBook} />
			if (type === EXERCISE_TYPE_LISTEN)
				return <FontAwesomeIcon style={{color: filterIconColor[EXERCISE_TYPE_LISTEN]}} icon={faHeadphones} />
			else return <></>
		} else {
			if (type === SKILL_PHONOLOGY)
				return <Image src={PhonologyIcon} />
			if (type === SKILL_GRAMMAR)
				return <Image src={GrammarIcon} />
			if (type === SKILL_UNDERSTANDING)
				return <Image src={UnderstandingIcon} />
			if (type === SKILL_VOCABULARY)
				return <Image src={VocabularyIcon} />
			if (type === SKILL_PRAGMATICS)
				return <FontAwesomeIcon style={{color: '#808080'}} icon={faBrain} />
			else return <></>
		}
	}


	return (
        <div className={`d-inline exerciseLibraryCard--title-icon ${type === SKILL_PHONOLOGY ? 'phonology-title-icon' : ''}`}>
			{getTypeIcon()}
		</div>
    )
}

export default ExerciseLibraryCardIcon