import React, { useState, useEffect } from 'react'
import Coin from 'images/coin.png'
import Image from 'react-bootstrap/Image'
import { useAppSelector } from 'stateHandling/hooks'

const CoinsCollected = () => {
	const coins = useAppSelector(state => state.currentExercise.stats.questionCoins)

	const [count, setCount] = useState(0)
	const duration = 1000 // 1 second

	useEffect(() => {
		let startValue = 0
		const interval = Math.floor(
			duration / (coins - 0))

		const counter = setInterval(() => {
			startValue += 1
			setCount(startValue)
			if (startValue >= coins) {
				clearInterval(counter)
			}
		}, interval)

		return () => {
			clearInterval(counter)
		}
	}, [coins])

	return coins > 0
		? <div className='text-center' data-cy='exercise_feedback_coins'>{count} <Image src={Coin} height='50' /></div>
		: <></>
}

export default CoinsCollected