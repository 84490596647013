import React, { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

import HubspotFeedbackForm from 'components/HubspotFeedbackForm'
import { changePreviousPage } from 'reducers/previousPageSlice'

import './AdminDashboardPage.css'
import headerImage from 'images/dashboard/dashboard_header_image.png'

const AdminDashboardPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(changePreviousPage('/dashboard'))
	}, [])

	const createUser = () => {
		navigate('/create_user')
	}

	const createExercise = () => {
		navigate('/create')
	}

	return (
		<div className='dashboard--content-div'>
			<Row>
				<Col className='dashboard--section'>
					<Row>
						<Col>
							<h3 className='mb-3' data-cy='dashboard_title'>
								{t('navigation.dashboard.header.title')}
							</h3>
							<Button className='mb-2 me-4' onClick={createUser} data-cy='add_patient_button'>
								<FontAwesomeIcon icon={faAdd}/>
								<span className='mx-2'>{t('edit_patient.list.add_patient_button')}</span>
							</Button>
							<Button data-cy="create_new_exercise" className='mb-2' onClick={createExercise}>
								<FontAwesomeIcon icon={faAdd}/>
								<span className='mx-2'>{t('create_exercise.create_button')}</span>
							</Button>
						</Col>
						<Col className='float-end text-end'>
							<img
								src={headerImage}
								className='dashboard--header-image'
								alt="Dashboard header image"
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={11} lg={5} className='dashboard--section'>
					<h3 className='mb-3' data-cy='dashboard_resources_title'>
						{t('navigation.dashboard.resources.title')}
					</h3>
					<div className='mb-3' data-cy='dashboard_resources_section'>
						<h5>{t('navigation.dashboard.resources.install_app.title')}</h5>
						<p className='mb-1'>{t('navigation.dashboard.resources.install_app.body')}</p>
						<a
							href={t('navigation.dashboard.resources.install_app.link')}
							className='cookies--link'
							target='_blank'
							rel='noopener noreferrer'
						>
							{t('navigation.dashboard.resources.install_app.link_text')}
						</a>
					</div>
					<div className='mb-3' data-cy='dashboard_resources_section'>
						<h5>{t('navigation.dashboard.resources.manuals.title')}</h5>
						<p className='mb-1'>{t('navigation.dashboard.resources.manuals.body')}</p>
						<a
							href={t('navigation.dashboard.resources.manuals.link')}
							className='cookies--link'
							target='_blank'
							rel='noopener noreferrer'
						>
							{t('navigation.dashboard.resources.manuals.link_text')}
						</a>
					</div>
					<div className='mb-3' data-cy='dashboard_resources_section'>
						<h5>{t('navigation.dashboard.resources.microphone_help.title')}</h5>
						<p className='mb-1'>{t('navigation.dashboard.resources.microphone_help.body')}</p>
						<a
							href={t('navigation.dashboard.resources.microphone_help.link')}
							className='cookies--link'
							target='_blank'
							rel='noopener noreferrer'
						>
							{t('navigation.dashboard.resources.microphone_help.link_text')}
						</a>
					</div>
					<div className='mb-3' data-cy='dashboard_resources_section'>
						<h5>{t('navigation.dashboard.resources.faq.title')}</h5>
						<p className='mb-1'>{t('navigation.dashboard.resources.faq.body')}</p>
						<a
							href={t('navigation.dashboard.resources.faq.link')}
							className='cookies--link'
							target='_blank'
							rel='noopener noreferrer'
						>
							{t('navigation.dashboard.resources.faq.link_text')}
						</a>
					</div>
				</Col>
				<Col className='dashboard--section'>
					<HubspotFeedbackForm />
				</Col>
			</Row>
		</div>
	)
}

export default AdminDashboardPage