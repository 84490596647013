import React from 'react'
import { useTranslation } from 'react-i18next'
import { AWS_URL } from 'utils/config'
import { StyleSheet, View, Text, Image as PdfImage } from '@react-pdf/renderer'

const DownloadableComprehensionExercise = ({ answerFormat, questions }) => {
	const { t } = useTranslation()

	const styles = StyleSheet.create({
		flexWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '5px' }
	})

	const getShuffledAnswers = (arr) => {
		if (answerFormat !== 'yes/no') {
			return [...arr].map(value => ({ value, sort: Math.random() }))
				.sort((a, b) => a.sort - b.sort)
				.map(({ value }) => value)
		} else {
			return arr
		}
	}

	return (
		<>
			{Object.values(questions).map((q, i) => {
				return (
					<View key={i} style={{ border: '1px solid #000000', borderRadius: '5px', margin: '16px 5px 0 5px', padding: '5px'}} wrap={false}>
						<Text data-cy={`download_page_comprehension_question_${i}`}>
							{i+1}.
						</Text>
						<View style={styles.flexWrapper}>
							{q.imageURL && q.imageURL !== 'undefined'
								? <PdfImage
									src={AWS_URL + q.imageURL}
									style={{ height: '130', borderRadius: '5px' }}
									crossOrigin='anonymous'
								></PdfImage>
								: <></>
							}
						</View>
						<View style={styles.flexWrapper}>
							<Text>{q.question.text}</Text>
						</View>
						<Text style={{ fontSize: '14px', margin: '5px' }}>
							{t('preview_exercise.download_exercise.comprehension_answer_instruction')}:
						</Text>
						<View style={styles.flexWrapper}>
							{getShuffledAnswers(q.answers).map((answerObj, j) =>
								(
									answerFormat !== 'image' ? (
										answerFormat !== 'yes/no'
											? <Text key={i+j} style={{ backgroundColor: '#000000', color: '#FFFFFF', borderRadius: '5px', margin: '15', padding: '5px' }}>
												{answerObj.answer.text}
											</Text>
											:
											<Text key={i+j} style={{ backgroundColor: answerObj.answer.text === 'yes' ? '#12B76A' : '#F04438', color: '#FFFFFF', borderRadius: '5px', margin: '15', padding: '5px' }}>
												{t(`exercise.${answerObj.answer.text}`)}
											</Text>
									) : (
										<PdfImage
											key={i+j}
											src={AWS_URL + answerObj.answer.text}
											style={{ width: '110', alignSelf: 'center', flex: '0 0 auto', borderRadius: '5px', margin: '15' }}
											crossOrigin='anonymous'
										></PdfImage>
									)
								)
							)}
						</View>
					</View>
				)
			})}
		</>
	)
}

export default DownloadableComprehensionExercise