import React from 'react'
import { faComment, faPen, faHeadphones, faBook, faQuestion}
	from '@fortawesome/free-solid-svg-icons'

import { DBExercise } from 'types/ExerciseTypes'
import ExerciseCardPatient from 'components/ExerciseCard/ExerciseCardPatient'
import './ExerciseCard.css'
import dialoListen from 'images/child_exercise_cards/dialo_listening.png'
import dialoRead from 'images/child_exercise_cards/dialo_reading.png'
import dialoSpeak from 'images/child_exercise_cards/dialo_speaking_bubble.png'
import dialoWrite from 'images/child_exercise_cards/dialo_writing.png'
import dialoDefault from 'images/avatars/dialo.png'

interface Props {
   exercise: DBExercise,
   clickEvent: (id: string) => void
   numQuestions: number,
   answerFormat: string,
   idx: string
}

const ExerciseCard = ({ exercise, clickEvent, numQuestions, answerFormat, idx }: Props) => {

	const getIcon = () => {
		let icon
		switch (answerFormat) {
		case 'speak':
			icon = faComment
			break
		case 'write':
			icon = faPen
			break
		case 'listen':
			icon = faHeadphones
			break
		case 'read':
			icon = faBook
			break
		default:
			icon = faQuestion
			console.error('Unknown answer format!')
		}
		return icon
	}

	const getChildImageString = () => {
		let childImageString
		switch (answerFormat) {
		case 'speak':
			childImageString = dialoSpeak
			break
		case 'write':
			childImageString = dialoWrite
			break
		case 'listen':
			childImageString = dialoListen
			break
		case 'read':
			childImageString = dialoRead
			break
		default:
			childImageString = dialoDefault
			console.error('Unknown answer format!')
		}
		return childImageString
	}

	return (
		<ExerciseCardPatient
			exercise={exercise}
			idx={idx}
			id={exercise.template + '_' + answerFormat + '_' + idx}
			answerFormat={answerFormat}
			numQuestions={numQuestions}
			clickEvent={clickEvent}
			getIcon={getIcon}
			getChildImageString={getChildImageString}
		/>
	)
}

export default ExerciseCard